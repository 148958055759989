import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { ensureTenantEstateQueryData } from '@/features/tenants/api/tenant-estates';
import { TenantSection } from '@/pages/tenant-section';

export const Route = createFileRoute('/_authenticated/_sidebar-layout/tenant/$tenantId/estates')({
  validateSearch: zodSearchValidator(
    z.object({
      view: fallback(z.enum(['grid', 'list']), 'grid'),
    })
  ),
  component: TenantEstateComponent,
  loader: ensureTenantEstateQueryData,
});

// eslint-disable-next-line react/function-component-definition
function TenantEstateComponent() {
  const { tenantId } = Route.useParams();

  return <TenantSection active="estates" id={tenantId} />;
}
