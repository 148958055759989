import * as React from 'react';

import { UploadDocumentsModal } from '@/features/estate-document/components/upload-documents-modal';
import { UploadDigitalTwinsModal } from '@/features/estate-media/components/media-cards/upload-digital-twins-modal';
import { UploadMediaModal } from '@/features/estate-media/components/upload-media-modal';
import { ViewMediaModal } from '@/features/estate-media/components/view-media-modal';
import { SelectThumbnailModal } from '@/features/estates-property/components/select-thumbnail-modal';
import { EditEmployeeRoleModal } from '@/features/tenants/components/modals/edit-employee-role-modal';

import { ConfirmationModal } from './confirmation-modal';
import { CreateEstateModal } from './create-estate-modal';
import { EditEstateModal } from './edit-estate-modal';
import { GlobalSearchModal } from './global-search-modal';
import { EMITTER_EVENT, eventEmitter } from './modals.utils';
import {
  ModalId,
  ModalProps,
  ModalRouteProps,
  ModalsBrowserProps,
  ModalsCallArgs,
} from './modals-root.types';

const ModalsBrowser: React.FC<ModalsBrowserProps> = ({ id, children, modalProps }) => {
  return React.Children.map(children, (child) => {
    const { id: childId, Modal } = child.props;
    if (childId === id) {
      return <Modal key={id} {...modalProps} />;
    }
    return null;
  });
};

const ModalRoute: React.FC<ModalRouteProps> = () => {
  return null;
};

export const ModalsRoot: React.FC = () => {
  const [modalId, setModalId] = React.useState<ModalId | ''>('');
  const [modalProps, setModalProps] = React.useState<ModalProps>();

  const setCurrentModalHandle = (...args: ModalsCallArgs) => {
    const [id, modalProps] = args;
    if (modalProps) setModalProps(modalProps);
    return setModalId(id);
  };

  React.useEffect(() => {
    eventEmitter.addListener(EMITTER_EVENT, setCurrentModalHandle);
    return () => {
      eventEmitter.removeListener(EMITTER_EVENT, setCurrentModalHandle);
    };
  }, []);

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    if (!modalId) {
      document.body.style.overflow = 'scroll';
    }
  }, [modalId]);

  if (!modalId) return null;

  return (
    <ModalsBrowser id={modalId} modalProps={modalProps as ModalProps}>
      <ModalRoute id="confirmation-modal" Modal={ConfirmationModal} />
      <ModalRoute id="edit-employee-role" Modal={EditEmployeeRoleModal} />
      <ModalRoute id="edit-estate" Modal={EditEstateModal} />
      <ModalRoute id="create-estate" Modal={CreateEstateModal} />
      <ModalRoute id="upload-documents" Modal={UploadDocumentsModal} />
      <ModalRoute id="upload-media" Modal={UploadMediaModal} />
      <ModalRoute id="upload-digital-twins" Modal={UploadDigitalTwinsModal} />
      <ModalRoute id="select-thumbnail" Modal={SelectThumbnailModal} />
      <ModalRoute id="global-search" Modal={GlobalSearchModal} />
      <ModalRoute id="view-media" Modal={ViewMediaModal} />
    </ModalsBrowser>
  );
};
