import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { QueryConfig } from '@/lib/react-query';
import { LoaderProps } from '@/lib/router';
import { api, extractData } from '@/lib/treaty';

export type TenantWithUsers = NonNullable<
  Awaited<ReturnType<ReturnType<typeof api.tenants>['users']['get']>>['data']
>;

type TenantId = TenantWithUsers['id'];
export type TenantEmployees = TenantWithUsers['employees'];
export type TenantContacts = TenantWithUsers['contacts'];

type TenantUsersQueryOptions = {
  queryConfig?: QueryConfig<typeof getTenantUsers>;
  id: TenantId;
};

const getTenantUsers = (id: TenantId) => {
  return extractData(api.tenants({ id }).users.get());
};

export const getTenantUsersOptions = (id: TenantId) => ({
  queryKey: ['tenants', id, 'users'],
  queryFn: () => getTenantUsers(id),
});

export const getTenantUsersQueryOptions = (id: TenantId) => {
  return queryOptions(getTenantUsersOptions(id));
};

export const ensureTenantUsersQueryData = ({ context: { queryClient }, params }: LoaderProps) => {
  return queryClient.ensureQueryData(getTenantUsersQueryOptions(params.tenantId));
};

export const useTenantUsers = ({ queryConfig, id }: TenantUsersQueryOptions) => {
  return useSuspenseQuery({ ...queryConfig, ...getTenantUsersQueryOptions(id) });
};
