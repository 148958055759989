import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { QueryConfig } from '@/lib/react-query';
import { LoaderProps } from '@/lib/router';
import { api, extractData } from '@/lib/treaty';

export type TenantEstates = NonNullable<
  Awaited<ReturnType<ReturnType<typeof api.tenants>['estates']['get']>>['data']
>;

type TenantId = TenantEstates[number]['id'];

type TenantEstateQueryOptions = {
  queryConfig?: QueryConfig<typeof getTenantEstates>;
  id: TenantId;
};

const getTenantEstates = (id: TenantId) => {
  return extractData(api.tenants({ id }).estates.get());
};

export const getTenantEstateOptions = (id: TenantId) => ({
  queryKey: ['tenants', id, 'estates'],
  queryFn: () => getTenantEstates(id),
});

export const getTenantEstateQueryOptions = (id: TenantId) => {
  return queryOptions(getTenantEstateOptions(id));
};

export const ensureTenantEstateQueryData = ({ context: { queryClient }, params }: LoaderProps) => {
  return queryClient.ensureQueryData(getTenantEstateQueryOptions(params.tenantId));
};

export const useTenantEstates = ({ queryConfig, id }: TenantEstateQueryOptions) => {
  return useSuspenseQuery({ ...queryConfig, ...getTenantEstateQueryOptions(id) });
};
