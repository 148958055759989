import { getRouteApi, useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { ButtonGroup } from '@/components/ui/button-group';
import { ViewSwitch } from '@/features/estates/components/view-switch';
import { useTenant } from '@/features/tenants/api/tenant';
import { TenantEstateSection } from '@/features/tenants/components/tenant-estate-section';
import { TenantUsersSection } from '@/features/tenants/components/tenant-users-section';

export const TenantSection = ({ id, active }: { id: string; active: 'estates' | 'users' }) => {
  const { data } = useTenant({ id });
  const navigate = useNavigate();
  const route = getRouteApi(`/_authenticated/_sidebar-layout/tenant/$tenantId/${active}`);

  return (
    <>
      <section className="mb-4 flex flex-col gap-4 text-center">
        <h2 className="text-3xl font-semibold">{data.name}</h2>
        <div className="flex flex-row justify-between">
          <ButtonGroup>
            <Button
              onClick={() =>
                navigate({ to: '/tenant/$tenantId/estates', params: { tenantId: id } })
              }
              variant={active === 'estates' ? 'contained' : 'outline'}
            >
              Estates
            </Button>
            <Button
              onClick={() => navigate({ to: '/tenant/$tenantId/users', params: { tenantId: id } })}
              variant={active === 'users' ? 'contained' : 'outline'}
            >
              Users
            </Button>
          </ButtonGroup>
          {active === 'estates' ? (
            <ViewSwitch route={route} from={`/tenant/$tenantId/${active}`} />
          ) : null}
        </div>
      </section>
      {active === 'estates' ? <TenantEstateSection /> : <TenantUsersSection />}
    </>
  );
};
