import { getRouteApi } from '@tanstack/react-router';

import { Estates } from '@/features/estates/api/estates';
import { EstateGrid } from '@/features/estates/components/estate-grid';
import { EstatesList } from '@/features/estates/components/estate-list';
import { useTenantEstates } from '@/features/tenants/api/tenant-estates';

const route = getRouteApi('/_authenticated/_sidebar-layout/tenant/$tenantId/estates');

export const TenantEstateSection = () => {
  const { tenantId } = route.useParams();
  const { view } = route.useSearch();

  const { data } = useTenantEstates({ id: tenantId });

  if (!data) return null;

  if (view === 'grid') {
    return <EstateGrid data={data as Estates} />;
  }

  return <EstatesList data={data as Estates} />;
};
