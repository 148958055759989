/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LogoutImport } from './routes/logout'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as AuthLayoutImport } from './routes/_auth-layout'
import { Route as AuthenticatedSidebarLayoutImport } from './routes/_authenticated/_sidebar-layout'
import { Route as AuthenticatedSidebarLayoutIndexImport } from './routes/_authenticated/_sidebar-layout/index'
import { Route as AuthenticatedSidebarLayoutUsersImport } from './routes/_authenticated/_sidebar-layout/users'
import { Route as AuthenticatedSidebarLayoutContactImport } from './routes/_authenticated/_sidebar-layout/contact'
import { Route as AuthenticatedSidebarLayoutAboutImport } from './routes/_authenticated/_sidebar-layout/about'
import { Route as AuthenticatedSidebarLayoutEstatesIndexImport } from './routes/_authenticated/_sidebar-layout/estates/index'
import { Route as AuthenticatedSidebarLayoutEstatesMeImport } from './routes/_authenticated/_sidebar-layout/estates/me'
import { Route as AuthenticatedSidebarLayoutTenantTenantIdIndexImport } from './routes/_authenticated/_sidebar-layout/tenant/$tenantId/index'
import { Route as AuthenticatedSidebarLayoutTenantTenantIdUsersImport } from './routes/_authenticated/_sidebar-layout/tenant/$tenantId/users'
import { Route as AuthenticatedSidebarLayoutTenantTenantIdEstatesImport } from './routes/_authenticated/_sidebar-layout/tenant/$tenantId/estates'
import { Route as AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutImport } from './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout'
import { Route as AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutIndexImport } from './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/index'
import { Route as AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImport } from './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media'
import { Route as AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutDocumentsImport } from './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/documents'
import { Route as AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaIndexImport } from './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/index'
import { Route as AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaSphericalImagesImport } from './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/spherical-images'
import { Route as AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImagesImport } from './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/images'
import { Route as AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFocusPointsImport } from './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/focus-points'
import { Route as AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFloorPlansImport } from './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/floor-plans'
import { Route as AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaDigitalTwinsImport } from './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/digital-twins'

// Create Virtual Routes

const CompleteProfileLazyImport = createFileRoute('/complete-profile')()
const AuthLayoutPasswordResetLazyImport = createFileRoute(
  '/_auth-layout/password-reset',
)()
const AuthLayoutLoginLazyImport = createFileRoute('/_auth-layout/login')()
const AuthLayoutCm416ujis000008jzbr1q4f86LazyImport = createFileRoute(
  '/_auth-layout/cm416ujis000008jzbr1q4f86',
)()
const AuthenticatedSidebarLayoutProfileLazyImport = createFileRoute(
  '/_authenticated/_sidebar-layout/profile',
)()
const AuthenticatedSidebarLayoutEstatesEstateIdImport = createFileRoute(
  '/_authenticated/_sidebar-layout/estates/$estateId',
)()

// Create/Update Routes

const CompleteProfileLazyRoute = CompleteProfileLazyImport.update({
  id: '/complete-profile',
  path: '/complete-profile',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/complete-profile.lazy').then((d) => d.Route),
)

const LogoutRoute = LogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const AuthLayoutRoute = AuthLayoutImport.update({
  id: '/_auth-layout',
  getParentRoute: () => rootRoute,
} as any)

const AuthLayoutPasswordResetLazyRoute =
  AuthLayoutPasswordResetLazyImport.update({
    id: '/password-reset',
    path: '/password-reset',
    getParentRoute: () => AuthLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_auth-layout/password-reset.lazy').then((d) => d.Route),
  )

const AuthLayoutLoginLazyRoute = AuthLayoutLoginLazyImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthLayoutRoute,
} as any).lazy(() =>
  import('./routes/_auth-layout/login.lazy').then((d) => d.Route),
)

const AuthLayoutCm416ujis000008jzbr1q4f86LazyRoute =
  AuthLayoutCm416ujis000008jzbr1q4f86LazyImport.update({
    id: '/cm416ujis000008jzbr1q4f86',
    path: '/cm416ujis000008jzbr1q4f86',
    getParentRoute: () => AuthLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_auth-layout/cm416ujis000008jzbr1q4f86.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedSidebarLayoutRoute = AuthenticatedSidebarLayoutImport.update(
  {
    id: '/_sidebar-layout',
    getParentRoute: () => AuthenticatedRoute,
  } as any,
)

const AuthenticatedSidebarLayoutIndexRoute =
  AuthenticatedSidebarLayoutIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_sidebar-layout/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedSidebarLayoutProfileLazyRoute =
  AuthenticatedSidebarLayoutProfileLazyImport.update({
    id: '/profile',
    path: '/profile',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_sidebar-layout/profile.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedSidebarLayoutUsersRoute =
  AuthenticatedSidebarLayoutUsersImport.update({
    id: '/users',
    path: '/users',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any)

const AuthenticatedSidebarLayoutContactRoute =
  AuthenticatedSidebarLayoutContactImport.update({
    id: '/contact',
    path: '/contact',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any)

const AuthenticatedSidebarLayoutAboutRoute =
  AuthenticatedSidebarLayoutAboutImport.update({
    id: '/about',
    path: '/about',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any)

const AuthenticatedSidebarLayoutEstatesEstateIdRoute =
  AuthenticatedSidebarLayoutEstatesEstateIdImport.update({
    id: '/estates/$estateId',
    path: '/estates/$estateId',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any)

const AuthenticatedSidebarLayoutEstatesIndexRoute =
  AuthenticatedSidebarLayoutEstatesIndexImport.update({
    id: '/estates/',
    path: '/estates/',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_sidebar-layout/estates/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedSidebarLayoutEstatesMeRoute =
  AuthenticatedSidebarLayoutEstatesMeImport.update({
    id: '/estates/me',
    path: '/estates/me',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_sidebar-layout/estates/me.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedSidebarLayoutTenantTenantIdIndexRoute =
  AuthenticatedSidebarLayoutTenantTenantIdIndexImport.update({
    id: '/tenant/$tenantId/',
    path: '/tenant/$tenantId/',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any)

const AuthenticatedSidebarLayoutTenantTenantIdUsersRoute =
  AuthenticatedSidebarLayoutTenantTenantIdUsersImport.update({
    id: '/tenant/$tenantId/users',
    path: '/tenant/$tenantId/users',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any)

const AuthenticatedSidebarLayoutTenantTenantIdEstatesRoute =
  AuthenticatedSidebarLayoutTenantTenantIdEstatesImport.update({
    id: '/tenant/$tenantId/estates',
    path: '/tenant/$tenantId/estates',
    getParentRoute: () => AuthenticatedSidebarLayoutRoute,
  } as any)

const AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutRoute =
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutImport.update({
    id: '/_dashboard-layout',
    getParentRoute: () => AuthenticatedSidebarLayoutEstatesEstateIdRoute,
  } as any)

const AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutIndexRoute =
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () =>
      AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRoute =
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImport.update({
    id: '/media',
    path: '/media',
    getParentRoute: () =>
      AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutRoute,
  } as any)

const AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutDocumentsRoute =
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutDocumentsImport.update(
    {
      id: '/documents',
      path: '/documents',
      getParentRoute: () =>
        AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/documents.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaIndexRoute =
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaIndexImport.update(
    {
      id: '/',
      path: '/',
      getParentRoute: () =>
        AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/index.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaSphericalImagesRoute =
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaSphericalImagesImport.update(
    {
      id: '/spherical-images',
      path: '/spherical-images',
      getParentRoute: () =>
        AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/spherical-images.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImagesRoute =
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImagesImport.update(
    {
      id: '/images',
      path: '/images',
      getParentRoute: () =>
        AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/images.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFocusPointsRoute =
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFocusPointsImport.update(
    {
      id: '/focus-points',
      path: '/focus-points',
      getParentRoute: () =>
        AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/focus-points.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFloorPlansRoute =
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFloorPlansImport.update(
    {
      id: '/floor-plans',
      path: '/floor-plans',
      getParentRoute: () =>
        AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/floor-plans.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaDigitalTwinsRoute =
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaDigitalTwinsImport.update(
    {
      id: '/digital-twins',
      path: '/digital-twins',
      getParentRoute: () =>
        AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRoute,
    } as any,
  ).lazy(() =>
    import(
      './routes/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/digital-twins.lazy'
    ).then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth-layout': {
      id: '/_auth-layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthLayoutImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutImport
      parentRoute: typeof rootRoute
    }
    '/complete-profile': {
      id: '/complete-profile'
      path: '/complete-profile'
      fullPath: '/complete-profile'
      preLoaderRoute: typeof CompleteProfileLazyImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/_sidebar-layout': {
      id: '/_authenticated/_sidebar-layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedSidebarLayoutImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_auth-layout/cm416ujis000008jzbr1q4f86': {
      id: '/_auth-layout/cm416ujis000008jzbr1q4f86'
      path: '/cm416ujis000008jzbr1q4f86'
      fullPath: '/cm416ujis000008jzbr1q4f86'
      preLoaderRoute: typeof AuthLayoutCm416ujis000008jzbr1q4f86LazyImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth-layout/login': {
      id: '/_auth-layout/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof AuthLayoutLoginLazyImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_auth-layout/password-reset': {
      id: '/_auth-layout/password-reset'
      path: '/password-reset'
      fullPath: '/password-reset'
      preLoaderRoute: typeof AuthLayoutPasswordResetLazyImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_authenticated/_sidebar-layout/about': {
      id: '/_authenticated/_sidebar-layout/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutAboutImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
    '/_authenticated/_sidebar-layout/contact': {
      id: '/_authenticated/_sidebar-layout/contact'
      path: '/contact'
      fullPath: '/contact'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutContactImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
    '/_authenticated/_sidebar-layout/users': {
      id: '/_authenticated/_sidebar-layout/users'
      path: '/users'
      fullPath: '/users'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutUsersImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
    '/_authenticated/_sidebar-layout/profile': {
      id: '/_authenticated/_sidebar-layout/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutProfileLazyImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
    '/_authenticated/_sidebar-layout/': {
      id: '/_authenticated/_sidebar-layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutIndexImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
    '/_authenticated/_sidebar-layout/estates/me': {
      id: '/_authenticated/_sidebar-layout/estates/me'
      path: '/estates/me'
      fullPath: '/estates/me'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutEstatesMeImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
    '/_authenticated/_sidebar-layout/estates/': {
      id: '/_authenticated/_sidebar-layout/estates/'
      path: '/estates'
      fullPath: '/estates'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutEstatesIndexImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
    '/_authenticated/_sidebar-layout/estates/$estateId': {
      id: '/_authenticated/_sidebar-layout/estates/$estateId'
      path: '/estates/$estateId'
      fullPath: '/estates/$estateId'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
    '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout': {
      id: '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout'
      path: '/estates/$estateId'
      fullPath: '/estates/$estateId'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutImport
      parentRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdRoute
    }
    '/_authenticated/_sidebar-layout/tenant/$tenantId/estates': {
      id: '/_authenticated/_sidebar-layout/tenant/$tenantId/estates'
      path: '/tenant/$tenantId/estates'
      fullPath: '/tenant/$tenantId/estates'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutTenantTenantIdEstatesImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
    '/_authenticated/_sidebar-layout/tenant/$tenantId/users': {
      id: '/_authenticated/_sidebar-layout/tenant/$tenantId/users'
      path: '/tenant/$tenantId/users'
      fullPath: '/tenant/$tenantId/users'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutTenantTenantIdUsersImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
    '/_authenticated/_sidebar-layout/tenant/$tenantId/': {
      id: '/_authenticated/_sidebar-layout/tenant/$tenantId/'
      path: '/tenant/$tenantId'
      fullPath: '/tenant/$tenantId'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutTenantTenantIdIndexImport
      parentRoute: typeof AuthenticatedSidebarLayoutImport
    }
    '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/documents': {
      id: '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/documents'
      path: '/documents'
      fullPath: '/estates/$estateId/documents'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutDocumentsImport
      parentRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutImport
    }
    '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media': {
      id: '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media'
      path: '/media'
      fullPath: '/estates/$estateId/media'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImport
      parentRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutImport
    }
    '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/': {
      id: '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/'
      path: '/'
      fullPath: '/estates/$estateId/'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutIndexImport
      parentRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutImport
    }
    '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/digital-twins': {
      id: '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/digital-twins'
      path: '/digital-twins'
      fullPath: '/estates/$estateId/media/digital-twins'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaDigitalTwinsImport
      parentRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImport
    }
    '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/floor-plans': {
      id: '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/floor-plans'
      path: '/floor-plans'
      fullPath: '/estates/$estateId/media/floor-plans'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFloorPlansImport
      parentRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImport
    }
    '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/focus-points': {
      id: '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/focus-points'
      path: '/focus-points'
      fullPath: '/estates/$estateId/media/focus-points'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFocusPointsImport
      parentRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImport
    }
    '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/images': {
      id: '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/images'
      path: '/images'
      fullPath: '/estates/$estateId/media/images'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImagesImport
      parentRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImport
    }
    '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/spherical-images': {
      id: '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/spherical-images'
      path: '/spherical-images'
      fullPath: '/estates/$estateId/media/spherical-images'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaSphericalImagesImport
      parentRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImport
    }
    '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/': {
      id: '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/'
      path: '/'
      fullPath: '/estates/$estateId/media/'
      preLoaderRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaIndexImport
      parentRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImport
    }
  }
}

// Create and export the route tree

interface AuthLayoutRouteChildren {
  AuthLayoutCm416ujis000008jzbr1q4f86LazyRoute: typeof AuthLayoutCm416ujis000008jzbr1q4f86LazyRoute
  AuthLayoutLoginLazyRoute: typeof AuthLayoutLoginLazyRoute
  AuthLayoutPasswordResetLazyRoute: typeof AuthLayoutPasswordResetLazyRoute
}

const AuthLayoutRouteChildren: AuthLayoutRouteChildren = {
  AuthLayoutCm416ujis000008jzbr1q4f86LazyRoute:
    AuthLayoutCm416ujis000008jzbr1q4f86LazyRoute,
  AuthLayoutLoginLazyRoute: AuthLayoutLoginLazyRoute,
  AuthLayoutPasswordResetLazyRoute: AuthLayoutPasswordResetLazyRoute,
}

const AuthLayoutRouteWithChildren = AuthLayoutRoute._addFileChildren(
  AuthLayoutRouteChildren,
)

interface AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRouteChildren {
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaDigitalTwinsRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaDigitalTwinsRoute
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFloorPlansRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFloorPlansRoute
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFocusPointsRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFocusPointsRoute
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImagesRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImagesRoute
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaSphericalImagesRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaSphericalImagesRoute
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaIndexRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaIndexRoute
}

const AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRouteChildren: AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRouteChildren =
  {
    AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaDigitalTwinsRoute:
      AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaDigitalTwinsRoute,
    AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFloorPlansRoute:
      AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFloorPlansRoute,
    AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFocusPointsRoute:
      AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFocusPointsRoute,
    AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImagesRoute:
      AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImagesRoute,
    AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaSphericalImagesRoute:
      AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaSphericalImagesRoute,
    AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaIndexRoute:
      AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaIndexRoute,
  }

const AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRouteWithChildren =
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRoute._addFileChildren(
    AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRouteChildren,
  )

interface AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutRouteChildren {
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutDocumentsRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutDocumentsRoute
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRouteWithChildren
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutIndexRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutIndexRoute
}

const AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutRouteChildren: AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutRouteChildren =
  {
    AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutDocumentsRoute:
      AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutDocumentsRoute,
    AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRoute:
      AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRouteWithChildren,
    AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutIndexRoute:
      AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutIndexRoute,
  }

const AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutRouteWithChildren =
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutRoute._addFileChildren(
    AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutRouteChildren,
  )

interface AuthenticatedSidebarLayoutEstatesEstateIdRouteChildren {
  AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutRouteWithChildren
}

const AuthenticatedSidebarLayoutEstatesEstateIdRouteChildren: AuthenticatedSidebarLayoutEstatesEstateIdRouteChildren =
  {
    AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutRoute:
      AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutRouteWithChildren,
  }

const AuthenticatedSidebarLayoutEstatesEstateIdRouteWithChildren =
  AuthenticatedSidebarLayoutEstatesEstateIdRoute._addFileChildren(
    AuthenticatedSidebarLayoutEstatesEstateIdRouteChildren,
  )

interface AuthenticatedSidebarLayoutRouteChildren {
  AuthenticatedSidebarLayoutAboutRoute: typeof AuthenticatedSidebarLayoutAboutRoute
  AuthenticatedSidebarLayoutContactRoute: typeof AuthenticatedSidebarLayoutContactRoute
  AuthenticatedSidebarLayoutUsersRoute: typeof AuthenticatedSidebarLayoutUsersRoute
  AuthenticatedSidebarLayoutProfileLazyRoute: typeof AuthenticatedSidebarLayoutProfileLazyRoute
  AuthenticatedSidebarLayoutIndexRoute: typeof AuthenticatedSidebarLayoutIndexRoute
  AuthenticatedSidebarLayoutEstatesMeRoute: typeof AuthenticatedSidebarLayoutEstatesMeRoute
  AuthenticatedSidebarLayoutEstatesIndexRoute: typeof AuthenticatedSidebarLayoutEstatesIndexRoute
  AuthenticatedSidebarLayoutEstatesEstateIdRoute: typeof AuthenticatedSidebarLayoutEstatesEstateIdRouteWithChildren
  AuthenticatedSidebarLayoutTenantTenantIdEstatesRoute: typeof AuthenticatedSidebarLayoutTenantTenantIdEstatesRoute
  AuthenticatedSidebarLayoutTenantTenantIdUsersRoute: typeof AuthenticatedSidebarLayoutTenantTenantIdUsersRoute
  AuthenticatedSidebarLayoutTenantTenantIdIndexRoute: typeof AuthenticatedSidebarLayoutTenantTenantIdIndexRoute
}

const AuthenticatedSidebarLayoutRouteChildren: AuthenticatedSidebarLayoutRouteChildren =
  {
    AuthenticatedSidebarLayoutAboutRoute: AuthenticatedSidebarLayoutAboutRoute,
    AuthenticatedSidebarLayoutContactRoute:
      AuthenticatedSidebarLayoutContactRoute,
    AuthenticatedSidebarLayoutUsersRoute: AuthenticatedSidebarLayoutUsersRoute,
    AuthenticatedSidebarLayoutProfileLazyRoute:
      AuthenticatedSidebarLayoutProfileLazyRoute,
    AuthenticatedSidebarLayoutIndexRoute: AuthenticatedSidebarLayoutIndexRoute,
    AuthenticatedSidebarLayoutEstatesMeRoute:
      AuthenticatedSidebarLayoutEstatesMeRoute,
    AuthenticatedSidebarLayoutEstatesIndexRoute:
      AuthenticatedSidebarLayoutEstatesIndexRoute,
    AuthenticatedSidebarLayoutEstatesEstateIdRoute:
      AuthenticatedSidebarLayoutEstatesEstateIdRouteWithChildren,
    AuthenticatedSidebarLayoutTenantTenantIdEstatesRoute:
      AuthenticatedSidebarLayoutTenantTenantIdEstatesRoute,
    AuthenticatedSidebarLayoutTenantTenantIdUsersRoute:
      AuthenticatedSidebarLayoutTenantTenantIdUsersRoute,
    AuthenticatedSidebarLayoutTenantTenantIdIndexRoute:
      AuthenticatedSidebarLayoutTenantTenantIdIndexRoute,
  }

const AuthenticatedSidebarLayoutRouteWithChildren =
  AuthenticatedSidebarLayoutRoute._addFileChildren(
    AuthenticatedSidebarLayoutRouteChildren,
  )

interface AuthenticatedRouteChildren {
  AuthenticatedSidebarLayoutRoute: typeof AuthenticatedSidebarLayoutRouteWithChildren
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedSidebarLayoutRoute: AuthenticatedSidebarLayoutRouteWithChildren,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof AuthenticatedSidebarLayoutRouteWithChildren
  '/logout': typeof LogoutRoute
  '/complete-profile': typeof CompleteProfileLazyRoute
  '/cm416ujis000008jzbr1q4f86': typeof AuthLayoutCm416ujis000008jzbr1q4f86LazyRoute
  '/login': typeof AuthLayoutLoginLazyRoute
  '/password-reset': typeof AuthLayoutPasswordResetLazyRoute
  '/about': typeof AuthenticatedSidebarLayoutAboutRoute
  '/contact': typeof AuthenticatedSidebarLayoutContactRoute
  '/users': typeof AuthenticatedSidebarLayoutUsersRoute
  '/profile': typeof AuthenticatedSidebarLayoutProfileLazyRoute
  '/': typeof AuthenticatedSidebarLayoutIndexRoute
  '/estates/me': typeof AuthenticatedSidebarLayoutEstatesMeRoute
  '/estates': typeof AuthenticatedSidebarLayoutEstatesIndexRoute
  '/estates/$estateId': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutRouteWithChildren
  '/tenant/$tenantId/estates': typeof AuthenticatedSidebarLayoutTenantTenantIdEstatesRoute
  '/tenant/$tenantId/users': typeof AuthenticatedSidebarLayoutTenantTenantIdUsersRoute
  '/tenant/$tenantId': typeof AuthenticatedSidebarLayoutTenantTenantIdIndexRoute
  '/estates/$estateId/documents': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutDocumentsRoute
  '/estates/$estateId/media': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRouteWithChildren
  '/estates/$estateId/': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutIndexRoute
  '/estates/$estateId/media/digital-twins': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaDigitalTwinsRoute
  '/estates/$estateId/media/floor-plans': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFloorPlansRoute
  '/estates/$estateId/media/focus-points': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFocusPointsRoute
  '/estates/$estateId/media/images': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImagesRoute
  '/estates/$estateId/media/spherical-images': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaSphericalImagesRoute
  '/estates/$estateId/media/': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaIndexRoute
}

export interface FileRoutesByTo {
  '': typeof AuthenticatedRouteWithChildren
  '/logout': typeof LogoutRoute
  '/complete-profile': typeof CompleteProfileLazyRoute
  '/cm416ujis000008jzbr1q4f86': typeof AuthLayoutCm416ujis000008jzbr1q4f86LazyRoute
  '/login': typeof AuthLayoutLoginLazyRoute
  '/password-reset': typeof AuthLayoutPasswordResetLazyRoute
  '/about': typeof AuthenticatedSidebarLayoutAboutRoute
  '/contact': typeof AuthenticatedSidebarLayoutContactRoute
  '/users': typeof AuthenticatedSidebarLayoutUsersRoute
  '/profile': typeof AuthenticatedSidebarLayoutProfileLazyRoute
  '/': typeof AuthenticatedSidebarLayoutIndexRoute
  '/estates/me': typeof AuthenticatedSidebarLayoutEstatesMeRoute
  '/estates': typeof AuthenticatedSidebarLayoutEstatesIndexRoute
  '/estates/$estateId': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutIndexRoute
  '/tenant/$tenantId/estates': typeof AuthenticatedSidebarLayoutTenantTenantIdEstatesRoute
  '/tenant/$tenantId/users': typeof AuthenticatedSidebarLayoutTenantTenantIdUsersRoute
  '/tenant/$tenantId': typeof AuthenticatedSidebarLayoutTenantTenantIdIndexRoute
  '/estates/$estateId/documents': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutDocumentsRoute
  '/estates/$estateId/media/digital-twins': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaDigitalTwinsRoute
  '/estates/$estateId/media/floor-plans': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFloorPlansRoute
  '/estates/$estateId/media/focus-points': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFocusPointsRoute
  '/estates/$estateId/media/images': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImagesRoute
  '/estates/$estateId/media/spherical-images': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaSphericalImagesRoute
  '/estates/$estateId/media': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth-layout': typeof AuthLayoutRouteWithChildren
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/logout': typeof LogoutRoute
  '/complete-profile': typeof CompleteProfileLazyRoute
  '/_authenticated/_sidebar-layout': typeof AuthenticatedSidebarLayoutRouteWithChildren
  '/_auth-layout/cm416ujis000008jzbr1q4f86': typeof AuthLayoutCm416ujis000008jzbr1q4f86LazyRoute
  '/_auth-layout/login': typeof AuthLayoutLoginLazyRoute
  '/_auth-layout/password-reset': typeof AuthLayoutPasswordResetLazyRoute
  '/_authenticated/_sidebar-layout/about': typeof AuthenticatedSidebarLayoutAboutRoute
  '/_authenticated/_sidebar-layout/contact': typeof AuthenticatedSidebarLayoutContactRoute
  '/_authenticated/_sidebar-layout/users': typeof AuthenticatedSidebarLayoutUsersRoute
  '/_authenticated/_sidebar-layout/profile': typeof AuthenticatedSidebarLayoutProfileLazyRoute
  '/_authenticated/_sidebar-layout/': typeof AuthenticatedSidebarLayoutIndexRoute
  '/_authenticated/_sidebar-layout/estates/me': typeof AuthenticatedSidebarLayoutEstatesMeRoute
  '/_authenticated/_sidebar-layout/estates/': typeof AuthenticatedSidebarLayoutEstatesIndexRoute
  '/_authenticated/_sidebar-layout/estates/$estateId': typeof AuthenticatedSidebarLayoutEstatesEstateIdRouteWithChildren
  '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutRouteWithChildren
  '/_authenticated/_sidebar-layout/tenant/$tenantId/estates': typeof AuthenticatedSidebarLayoutTenantTenantIdEstatesRoute
  '/_authenticated/_sidebar-layout/tenant/$tenantId/users': typeof AuthenticatedSidebarLayoutTenantTenantIdUsersRoute
  '/_authenticated/_sidebar-layout/tenant/$tenantId/': typeof AuthenticatedSidebarLayoutTenantTenantIdIndexRoute
  '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/documents': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutDocumentsRoute
  '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaRouteWithChildren
  '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutIndexRoute
  '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/digital-twins': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaDigitalTwinsRoute
  '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/floor-plans': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFloorPlansRoute
  '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/focus-points': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaFocusPointsRoute
  '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/images': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaImagesRoute
  '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/spherical-images': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaSphericalImagesRoute
  '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/': typeof AuthenticatedSidebarLayoutEstatesEstateIdDashboardLayoutMediaIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/logout'
    | '/complete-profile'
    | '/cm416ujis000008jzbr1q4f86'
    | '/login'
    | '/password-reset'
    | '/about'
    | '/contact'
    | '/users'
    | '/profile'
    | '/'
    | '/estates/me'
    | '/estates'
    | '/estates/$estateId'
    | '/tenant/$tenantId/estates'
    | '/tenant/$tenantId/users'
    | '/tenant/$tenantId'
    | '/estates/$estateId/documents'
    | '/estates/$estateId/media'
    | '/estates/$estateId/'
    | '/estates/$estateId/media/digital-twins'
    | '/estates/$estateId/media/floor-plans'
    | '/estates/$estateId/media/focus-points'
    | '/estates/$estateId/media/images'
    | '/estates/$estateId/media/spherical-images'
    | '/estates/$estateId/media/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/logout'
    | '/complete-profile'
    | '/cm416ujis000008jzbr1q4f86'
    | '/login'
    | '/password-reset'
    | '/about'
    | '/contact'
    | '/users'
    | '/profile'
    | '/'
    | '/estates/me'
    | '/estates'
    | '/estates/$estateId'
    | '/tenant/$tenantId/estates'
    | '/tenant/$tenantId/users'
    | '/tenant/$tenantId'
    | '/estates/$estateId/documents'
    | '/estates/$estateId/media/digital-twins'
    | '/estates/$estateId/media/floor-plans'
    | '/estates/$estateId/media/focus-points'
    | '/estates/$estateId/media/images'
    | '/estates/$estateId/media/spherical-images'
    | '/estates/$estateId/media'
  id:
    | '__root__'
    | '/_auth-layout'
    | '/_authenticated'
    | '/logout'
    | '/complete-profile'
    | '/_authenticated/_sidebar-layout'
    | '/_auth-layout/cm416ujis000008jzbr1q4f86'
    | '/_auth-layout/login'
    | '/_auth-layout/password-reset'
    | '/_authenticated/_sidebar-layout/about'
    | '/_authenticated/_sidebar-layout/contact'
    | '/_authenticated/_sidebar-layout/users'
    | '/_authenticated/_sidebar-layout/profile'
    | '/_authenticated/_sidebar-layout/'
    | '/_authenticated/_sidebar-layout/estates/me'
    | '/_authenticated/_sidebar-layout/estates/'
    | '/_authenticated/_sidebar-layout/estates/$estateId'
    | '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout'
    | '/_authenticated/_sidebar-layout/tenant/$tenantId/estates'
    | '/_authenticated/_sidebar-layout/tenant/$tenantId/users'
    | '/_authenticated/_sidebar-layout/tenant/$tenantId/'
    | '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/documents'
    | '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media'
    | '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/'
    | '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/digital-twins'
    | '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/floor-plans'
    | '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/focus-points'
    | '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/images'
    | '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/spherical-images'
    | '/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthLayoutRoute: typeof AuthLayoutRouteWithChildren
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  LogoutRoute: typeof LogoutRoute
  CompleteProfileLazyRoute: typeof CompleteProfileLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthLayoutRoute: AuthLayoutRouteWithChildren,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  LogoutRoute: LogoutRoute,
  CompleteProfileLazyRoute: CompleteProfileLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth-layout",
        "/_authenticated",
        "/logout",
        "/complete-profile"
      ]
    },
    "/_auth-layout": {
      "filePath": "_auth-layout.tsx",
      "children": [
        "/_auth-layout/cm416ujis000008jzbr1q4f86",
        "/_auth-layout/login",
        "/_auth-layout/password-reset"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/_sidebar-layout"
      ]
    },
    "/logout": {
      "filePath": "logout.tsx"
    },
    "/complete-profile": {
      "filePath": "complete-profile.lazy.tsx"
    },
    "/_authenticated/_sidebar-layout": {
      "filePath": "_authenticated/_sidebar-layout.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/_sidebar-layout/about",
        "/_authenticated/_sidebar-layout/contact",
        "/_authenticated/_sidebar-layout/users",
        "/_authenticated/_sidebar-layout/profile",
        "/_authenticated/_sidebar-layout/",
        "/_authenticated/_sidebar-layout/estates/me",
        "/_authenticated/_sidebar-layout/estates/",
        "/_authenticated/_sidebar-layout/estates/$estateId",
        "/_authenticated/_sidebar-layout/tenant/$tenantId/estates",
        "/_authenticated/_sidebar-layout/tenant/$tenantId/users",
        "/_authenticated/_sidebar-layout/tenant/$tenantId/"
      ]
    },
    "/_auth-layout/cm416ujis000008jzbr1q4f86": {
      "filePath": "_auth-layout/cm416ujis000008jzbr1q4f86.lazy.tsx",
      "parent": "/_auth-layout"
    },
    "/_auth-layout/login": {
      "filePath": "_auth-layout/login.lazy.tsx",
      "parent": "/_auth-layout"
    },
    "/_auth-layout/password-reset": {
      "filePath": "_auth-layout/password-reset.lazy.tsx",
      "parent": "/_auth-layout"
    },
    "/_authenticated/_sidebar-layout/about": {
      "filePath": "_authenticated/_sidebar-layout/about.tsx",
      "parent": "/_authenticated/_sidebar-layout"
    },
    "/_authenticated/_sidebar-layout/contact": {
      "filePath": "_authenticated/_sidebar-layout/contact.tsx",
      "parent": "/_authenticated/_sidebar-layout"
    },
    "/_authenticated/_sidebar-layout/users": {
      "filePath": "_authenticated/_sidebar-layout/users.tsx",
      "parent": "/_authenticated/_sidebar-layout"
    },
    "/_authenticated/_sidebar-layout/profile": {
      "filePath": "_authenticated/_sidebar-layout/profile.lazy.tsx",
      "parent": "/_authenticated/_sidebar-layout"
    },
    "/_authenticated/_sidebar-layout/": {
      "filePath": "_authenticated/_sidebar-layout/index.tsx",
      "parent": "/_authenticated/_sidebar-layout"
    },
    "/_authenticated/_sidebar-layout/estates/me": {
      "filePath": "_authenticated/_sidebar-layout/estates/me.tsx",
      "parent": "/_authenticated/_sidebar-layout"
    },
    "/_authenticated/_sidebar-layout/estates/": {
      "filePath": "_authenticated/_sidebar-layout/estates/index.tsx",
      "parent": "/_authenticated/_sidebar-layout"
    },
    "/_authenticated/_sidebar-layout/estates/$estateId": {
      "filePath": "_authenticated/_sidebar-layout/estates/$estateId",
      "parent": "/_authenticated/_sidebar-layout",
      "children": [
        "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout"
      ]
    },
    "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout": {
      "filePath": "_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout.tsx",
      "parent": "/_authenticated/_sidebar-layout/estates/$estateId",
      "children": [
        "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/documents",
        "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media",
        "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/"
      ]
    },
    "/_authenticated/_sidebar-layout/tenant/$tenantId/estates": {
      "filePath": "_authenticated/_sidebar-layout/tenant/$tenantId/estates.tsx",
      "parent": "/_authenticated/_sidebar-layout"
    },
    "/_authenticated/_sidebar-layout/tenant/$tenantId/users": {
      "filePath": "_authenticated/_sidebar-layout/tenant/$tenantId/users.tsx",
      "parent": "/_authenticated/_sidebar-layout"
    },
    "/_authenticated/_sidebar-layout/tenant/$tenantId/": {
      "filePath": "_authenticated/_sidebar-layout/tenant/$tenantId/index.tsx",
      "parent": "/_authenticated/_sidebar-layout"
    },
    "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/documents": {
      "filePath": "_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/documents.tsx",
      "parent": "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout"
    },
    "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media": {
      "filePath": "_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media.tsx",
      "parent": "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout",
      "children": [
        "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/digital-twins",
        "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/floor-plans",
        "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/focus-points",
        "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/images",
        "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/spherical-images",
        "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/"
      ]
    },
    "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/": {
      "filePath": "_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/index.tsx",
      "parent": "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout"
    },
    "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/digital-twins": {
      "filePath": "_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/digital-twins.tsx",
      "parent": "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media"
    },
    "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/floor-plans": {
      "filePath": "_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/floor-plans.tsx",
      "parent": "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media"
    },
    "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/focus-points": {
      "filePath": "_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/focus-points.tsx",
      "parent": "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media"
    },
    "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/images": {
      "filePath": "_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/images.tsx",
      "parent": "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media"
    },
    "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/spherical-images": {
      "filePath": "_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/spherical-images.tsx",
      "parent": "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media"
    },
    "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/": {
      "filePath": "_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media/index.tsx",
      "parent": "/_authenticated/_sidebar-layout/estates/$estateId/_dashboard-layout/media"
    }
  }
}
ROUTE_MANIFEST_END */
