import { getRouteApi } from '@tanstack/react-router';

import { useTenantUsers } from '../api/tenant-users';
import { TenantContactList } from './tenant-contacts-list';
import { TenantEmployeeList } from './tenant-employee-list';

const route = getRouteApi('/_authenticated/_sidebar-layout/tenant/$tenantId/users');

export const TenantUsersSection = () => {
  const { tenantId } = route.useParams();

  const { data } = useTenantUsers({ id: tenantId });

  if (!data) return null;

  return (
    <section className="flex flex-col gap-4">
      <TenantEmployeeList employees={data.employees} tenantId={tenantId} />
      <TenantContactList contacts={data.contacts} tenantId={tenantId} />
    </section>
  );
};
