import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { TrashIcon } from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { queryClient } from '@/lib/react-query';

import { useDeleteEmployee } from '../api/tenant-employee';
import { getTenantUsersOptions } from '../api/tenant-users';

interface DeleteEmployeeButtonProps {
  id: string;
  tenantId: string;
}

export const DeleteEmployeeButton = ({ id, tenantId }: DeleteEmployeeButtonProps) => {
  const { t } = useTranslation();

  const { mutate: deleteEmployee } = useDeleteEmployee({
    mutationConfig: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: getTenantUsersOptions(tenantId).queryKey });
        toast.success(t('successfully-deleted-team-member', { autoClose: 100 }));
      },
    },
  });

  const handleDelete = () => {
    deleteEmployee({ employeeUserId: id, tenantId });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="sm" variant="destructive">
          <TrashIcon aria-hidden />
          <span className="sr-only">{t('delete')}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem asChild>
          <Button className="w-full text-white" onClick={handleDelete} variant="destructive">
            <span>{t('delete')}</span>
          </Button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
